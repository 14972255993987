<div class="container text-center">
  <ng-container *ngFor="let p of portfolio">
    <div class="logo">
      <img src="assets/images/logos/{{p.logo}}" [alt]="'logo '+p.name">
    </div>
    <h1>{{p.name}}</h1>

    <ngx-slick-carousel class="carousel mt-4 mb-4" #slickModal="slick-carousel" [config]="slideConfig">
      <div class="p-3" ngxSlickItem *ngFor="let pm of p.mockups" class="slide">
        <img src="assets/{{ pm }}.png" alt="" width="100%">
      </div>
    </ngx-slick-carousel>
    <h2 class="mt-3 mb-3">"{{p.marketingDescription}}"</h2>
    <h3>{{p.description}}</h3>
  </ng-container>

</div>


<ng-container *ngFor="let p of portfolio">
  <div class="text-center" id="proyects">
    <ng-container *ngIf="p.availability.android || p.availability.ios || p.availability.web">
      <div class="container">

        <h4 class="mt-4 text-uppercase">{{'landing.see_proyects' | translate}}</h4>
        <div class="links d-flex justify-content-center mt-3 mb-5">
          <a class="icon d-flex flex-column justify-content-center mr-2 ml-2" [href]="p.availability.android"
            *ngIf="p.availability.android" target="_blank">
            <img class="mx-auto" width="48" src="assets/images/android.svg" alt="">
            <span>android</span>
          </a>
          <a class="icon d-flex flex-column justify-content-center mr-2 ml-2" [href]="p.availability.ios"
            *ngIf="p.availability.ios" target="_blank">
            <img width="48" class="mx-auto" src="assets/images/ios.svg" alt="">
            <span>ios</span></a>
          <a class="icon d-flex flex-column justify-content-center mr-2 ml-2" [href]="p.availability.web"
            *ngIf="p.availability.web" target="_blank">
            <img width="48" class="mx-auto" src="assets/images/web.svg" alt="">
            <span>web</span></a>
        </div>
      </div>
    </ng-container> 

  </div>

  <div class="text-center" id="tech">
    <div class="container">
      <h4 class="mt-4 text-uppercase">{{'landing.technologies' | translate}}</h4>
      <div class="col-md-8 mx-auto">
        <div class="row justify-content-center">
          <div class="col-md-3 col-6 mb-3" *ngFor="let t of p.technologies">
            <img width="90%" [src]="'assets/images/technologies/'+t+'.png'" [title]="t" [alt]="'logo '+ t">
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--
  <section class="gradient-bg1 text-center" id="clients">
    <div class="container">
     
      <div class="row">
        <div class="col-md-12">
          <div class="heading-area mx-570 mb-5">
            <h2 class="title text-white m-0">{{'landing.some' | translate}} <span class="js-rotating">geniales,
                ideales</span> {{'landing.words' | translate}}</h2>
          </div>
        </div>
      </div>
     
      <div class="row">
        <div class="col-md-12">
          <div class="owl-carousel wow zoomIn" id="testimonial-slider">
            <div class="item" *ngFor="let p of portfolio">
              <ng-container *ngFor="let pq of p.quotes">
                <p class="para">{{pq.text}}</p>
                <h5 class="name gradient-text1">{{pq.name}}</h5>
                <span class="designation">{{pq.position}}</span>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
     
      <div class="owl-dots" id="testimonials-avatar">
       
        <button class="owl-dot active" data-position="22%,auto,auto,5%">
          <img alt="image" src="assets/images/avatar-1.png"></button>

        <button class="owl-dot active" data-position="30%,auto,auto,16%">
          <img alt="image" src="assets/images/avatar-2.png"></button>

        <button class="owl-dot active" data-position="auto,auto,38%,7%">
          <img alt="image" src="assets/images/avatar-3.png"></button>

        <button class="owl-dot active" data-position="auto,auto,23%,18%">
          <img alt="image" src="assets/images/avatar-7.png"></button>

      
        <button class="owl-dot active" data-position="20%,19%,auto,auto">
          <img alt="image" src="assets/images/avatar-5.png"></button>

        <button class="owl-dot active" data-position="28%,6%,auto,auto">
          <img alt="image" src="assets/images/avatar-6.png"></button>

        <button class="owl-dot active" data-position="40%,15%,auto,auto">
          <img alt="image" src="assets/images/avatar-4.png"></button>

        <button class="owl-dot active" data-position="auto,21%,22%,auto">
          <img alt="image" src="assets/images/avatar-2.png"></button>
      </div>
    </div>
  </section>
-->
</ng-container>

