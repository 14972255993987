import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Constants} from '../../shared/Constants';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  portfolio:any
 
 
  message: FormGroup;
  constructor(
    private location: Location,
    private fb: FormBuilder,
    ) { }

  ngOnInit(): void { 
    this.portfolio = Constants.portfolio
    this.message = this.fb.group({
      email: new FormControl('', [Validators.required, Validators.email]),
      message: new FormControl('', Validators.required)
    });
    
  }



  sendMessage(){
    const data = {
      email: this.message.value.email,
      message: this.message.value.message 
    };
    if(data.email  && data.message) {
      this.sendWhatsapp(data.email, data.message )

      console.log(data.email  && data.message)
    }
  }

  sendWhatsapp(email, text){
    window.open("" + `https://api.whatsapp.com/send?phone=541163576089&text=Email: ${email} Mensaje: ${text}`, '_blank');
  }

}
