import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'pink-bike';
  browserLang = this.translate.getBrowserLang();
  navbar
  constructor(
    private translate: TranslateService,
    private location: Location
    ) {
    this.translate.setDefaultLang('es');
    translate.use(this.browserLang);
  }

  getUrl(){

    return this.location.path().split("/")[1]
  }
}
