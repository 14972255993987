import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {

  message: FormGroup;
  constructor(
    private location: Location,
    private router:ActivatedRoute,
    private fb: FormBuilder,
    ) { }

  ngOnInit(){
    this.message = this.fb.group({
      email: new FormControl('', [Validators.required, Validators.email]),
      message: new FormControl('', Validators.required)
    });
  }

  sendMessage(){
    const data = {
      email: this.message.value.email,
      message: this.message.value.message 
    };
    if(data.email  && data.message) {
      this.sendWhatsapp(data.email, data.message )

      console.log(data.email  && data.message)
    }
  }

  sendWhatsapp(email, text){
    window.open("" + `https://api.whatsapp.com/send?phone=541163576089&text=Email: ${email} Mensaje: ${text}`, '_blank');
  }

  goTo(url) {
    location.href = url;
  }

  getUrl(){
    return this.location.path().split("/")[1]
  }

}
