import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Constants} from '../../../shared/Constants';

@Component({
  selector: 'app-proyect-details',
  templateUrl: './proyect-details.component.html',
  styleUrls: ['./proyect-details.component.scss']
})
export class ProyectDetailsComponent implements OnInit {
  portfolio:any
  proyect:string
  
  slideConfig = {"slidesToShow": 4, "slidesToScroll": 4};
  
  
  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void { 
    this.proyect = this.route.snapshot.paramMap.get('proyect')
    this.portfolio = Constants.portfolio.filter((x) => x.name == this.proyect)
    console.log(this.portfolio)
  }




}
