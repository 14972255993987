<section class="p-0 no-transition cursor-light" id="home">
  <h2 class="d-none">hidden</h2>
  <div class="rev_slider_wrapper fullscreen-container" data-alias="PinkBike-agency-1" data-source="gallery"
    id="rev_slider_17_1_wrapper" style="background:transparent;padding:0px;">
    <!-- START REVOLUTION SLIDER 5.4.8.1 fullscreen mode -->
    <div class="rev_slider fullscreenbanner" data-version="5.4.8.1" id="rev_slider_17_1" style="display:none;">
      <ul>
        <!-- SLIDE  -->
        <li data-description="" data-easein="default" data-easeout="default" data-hideafterloop="0"
          data-hideslideonmobile="off" data-index="rs-43" data-masterspeed="default" data-param1="" data-param10=""
          data-param2="" data-param3="" data-param4="" data-param5="" data-param6="" data-param7="" data-param8=""
          data-param9="" data-rotate="0" data-saveperformance="off" data-slotamount="default" data-title="Slide"
          data-transition="fade">
          <!--OVERLAY-->
          <div class="gradient-bg1 bg-overlay"></div>
          <!-- LAYER NR. 3 -->
          <div class="tp-caption   tp-resizeme"
            data-frames='[{"delay":220,"speed":500,"frame":"0","from":"opacity:0;","to":"o:1;rZ:339;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
            data-height="none" data-hoffset="['-26','-26','83','83']" data-paddingbottom="[0,0,0,0]"
            data-paddingleft="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingtop="[0,0,0,0]"
            data-responsive_offset="on" data-textAlign="['inherit','inherit','inherit','inherit']" data-type="image"
            data-voffset="['98','98','60','60']" data-whitespace="nowrap" data-width="none"
            data-x="['left','left','left','left']" data-y="['bottom','bottom','bottom','bottom']" id="slide-43-layer-5"
            style="z-index: 7;">
            <div class="rs-looped rs-slideloop" data-easing="" data-speed="5" data-xe="0" data-xs="0" data-ye="15"
              data-ys="-15"><img alt="" data-hh="['87px','87px','87px','87px']" data-no-retina
                data-ww="['44px','44px','44px','44px']" src="assets/images/shape-6.png">
            </div>
          </div>
          <!-- LAYER NR. 4 -->
          <div class="tp-caption   tp-resizeme" data-basealign="slide"
            data-frames='[{"delay":210,"speed":500,"frame":"0","from":"opacity:0;","to":"o:1;rZ:358;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
            data-height="none" data-hoffset="['700','700','700','530']" data-paddingbottom="[0,0,0,0]"
            data-paddingleft="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingtop="[0,0,0,0]"
            data-responsive_offset="on" data-textAlign="['inherit','inherit','inherit','inherit']" data-type="image"
            data-voffset="['148','148','148','148']" data-whitespace="nowrap" data-width="none"
            data-x="['center','center','center','center']" data-y="['middle','middle','middle','middle']"
            id="slide-43-layer-6" style="z-index: 8;">
            <div class="rs-looped rs-slideloop" data-easing="Power0.easeIn" data-speed="5" data-xe="0" data-xs="0"
              data-ye="20" data-ys="0"><img alt="" data-hh="['87px','87px','87px','87px']" data-no-retina
                data-ww="['24px','24px','24px','24px']" src="assets/images/shape-5.png"></div>
          </div>
          <!-- LAYER NR. 5 -->
          <div class="tp-caption   tp-resizeme" data-fontsize="['20','20','20','20']"
            data-frames='[{"delay":220,"speed":500,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
            data-height="none" data-hoffset="['275','275','171','120']" data-paddingbottom="[0,0,0,0]"
            data-paddingleft="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingtop="[0,0,0,0]"
            data-responsive_offset="on" data-textAlign="['inherit','inherit','inherit','inherit']" data-type="image"
            data-voffset="['270','270','261','190']" data-whitespace="nowrap" data-width="none"
            data-x="['center','center','center','center']" data-y="['middle','middle','middle','middle']"
            id="slide-43-layer-7" style="z-index: 9;">
            <div class="rs-looped rs-wave" data-angle="0" data-origin="50% 50%" data-radius="15px" data-speed="12"><img
                alt="" data-hh="['67px','67px','67px','67px']" data-no-retina data-ww="['68px','68px','68px','68px']"
                src="assets/images/shape-4.png"></div>
          </div>
          <!-- LAYER NR. 6 -->
          <div class="tp-caption   tp-resizeme"
            data-frames='[{"delay":190,"speed":500,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
            data-height="none" data-hoffset="['413','413','268','204']" data-paddingbottom="[0,0,0,0]"
            data-paddingleft="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingtop="[0,0,0,0]"
            data-responsive_offset="on" data-textAlign="['inherit','inherit','inherit','inherit']" data-type="image"
            data-voffset="['-205','-205','-259','-145']" data-whitespace="nowrap" data-width="none"
            data-x="['center','center','center','center']" data-y="['middle','middle','middle','middle']"
            id="slide-43-layer-8" style="z-index: 10;">
            <div class="rs-looped rs-rotate" data-easing="Power0.easeIn" data-enddeg="360" data-origin="50% 50%"
              data-speed="15" data-startdeg="0"><img alt="" data-hh="['69px','69px','69px','69px']" data-no-retina
                data-ww="['67px','67px','67px','67px']" src="assets/images/shape-3.png"></div>
          </div>
          <!-- LAYER NR. 7 -->
          <div class="tp-caption   tp-resizeme"
            data-frames='[{"delay":190,"speed":500,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
            data-height="none" data-hoffset="['436','436','259','145']" data-paddingbottom="[0,0,0,0]"
            data-paddingleft="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingtop="[0,0,0,0]"
            data-responsive_offset="on" data-textAlign="['inherit','inherit','inherit','inherit']" data-type="image"
            data-voffset="['204','204','96','73']" data-whitespace="nowrap" data-width="none"
            data-x="['left','left','left','left']" data-y="['top','top','top','top']" id="slide-43-layer-9"
            style="z-index: 11;">
            <div class="rs-looped rs-pendulum" data-easing="" data-enddeg="20" data-origin="50% 50%" data-speed="12"
              data-startdeg="-20"><img alt="" data-hh="['52px','52px','52px','52px']" data-no-retina
                data-ww="['51px','51px','51px','51px']" src="assets/images/shape-1.png"></div>
          </div>
          <!-- LAYER NR. 8 -->
          <div class="tp-caption   tp-resizeme" data-basealign="slide"
            data-frames='[{"delay":170,"speed":500,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
            data-height="none" data-hoffset="['39','39','94','58']" data-paddingbottom="[0,0,0,0]"
            data-paddingleft="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingtop="[0,0,0,0]"
            data-responsive_offset="on" data-textAlign="['inherit','inherit','inherit','inherit']" data-type="image"
            data-voffset="['109','109','64','69']" data-whitespace="nowrap" data-width="none"
            data-x="['right','right','right','right']" data-y="['top','top','top','top']" id="slide-43-layer-10"
            style="z-index: 12;">
            <div class="rs-looped rs-slideloop" data-easing="" data-speed="2" data-xe="15" data-xs="0" data-ye="0"
              data-ys="0"><img alt="" data-hh="['14px','14px','14px','14px']" data-no-retina
                data-ww="['50px','50px','50px','50px']" src="assets/images/shape-2.png"></div>
          </div>
          <!-- LAYER NR. 9 -->
          <div class="tp-caption tp-resizeme gradient-text1" data-fontsize="['70','65','60','50']"
            data-frames='[{"delay":660,"speed":1500,"frame":"0","from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","mask":"x:0px;y:[100%];s:inherit;e:inherit;","to":"o:1;","ease":"Power2.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
            data-height="none" data-hoffset="['320','250','0','0']" data-lineheight="['80','75','70','60']"
            data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" data-paddingright="[0,0,0,0]"
            data-paddingtop="[0,0,0,0]" data-responsive_offset="on" data-textAlign="['left','left','center','center']"
            data-type="text" data-voffset="['-125','-125','-125','-110']" data-whitespace="nowrap"
            data-width="['650','650','600','500']" data-x="['center','center','center','center']"
            data-y="['middle','middle','middle','middle']" id="slide-43-layer-13"
            style="z-index: 13; min-width: 650px; max-width: 650px; white-space: nowrap; font-size: 70px; line-height: 80px; font-weight: 800; color: #ffffff; letter-spacing: 0px;font-family:Montserrat;">
            PinkBike
          </div>
          <!-- LAYER NR. 10 -->
          <div class="tp-caption   tp-resizeme" data-fontsize="['60','50','45','40']"
            data-frames='[{"delay":1840,"split":"chars","splitdelay":0.1,"speed":1000,"split_direction":"forward","frame":"0","from":"sX:0.8;sY:0.8;opacity:0;","to":"o:1;","ease":"Power4.easeOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"nothing"}]'
            data-height="none" data-hoffset="['320','250','0','0']" data-lineheight="['70','75','70','60']"
            data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" data-paddingright="[0,0,0,0]"
            data-paddingtop="[0,0,0,0]" data-responsive_offset="on" data-textAlign="['left','left','center','center']"
            data-type="text" data-voffset="['-40','-40','-40','-40']" data-whitespace="nowrap"
            data-width="['650','650','600','500']" data-x="['center','center','center','center']"
            data-y="['middle','middle','middle','middle']" id="slide-43-layer-11"
            style="z-index: 14; min-width: 650px; max-width: 650px; white-space: nowrap; font-size: 0.9em; line-height: 70px; font-weight: 700; color: #ffffff; letter-spacing: 0px;font-family:Montserrat;">
            Product Development
          </div>
          <!-- LAYER NR. 11 -->
          <div class="tp-caption   tp-resizeme" data-fontsize="['20','20','18','17']"
            data-frames='[{"delay":2360,"speed":1500,"frame":"0","from":"y:50px;opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
            data-height="none" data-hoffset="['325','210','0','0']" data-paddingbottom="[0,0,0,0]"
            data-paddingleft="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingtop="[0,0,0,0]"
            data-responsive_offset="on" data-textAlign="['left','left','center','center']" data-type="text"
            data-voffset="['80','75','65','57']" data-whitespace="normal" data-width="['651','550','600','500']"
            data-x="['center','center','center','center']" data-y="['middle','middle','middle','middle']"
            id="slide-43-layer-14"
            style="z-index: 15; min-width: 651px; max-width: 651px; white-space: normal; font-size: 20px; line-height: 30px; font-weight: 300; color: #ffffff; letter-spacing: 0px;font-family:Roboto;">
            {{'landing.agency' | translate}}
            
          </div>
          <!-- LAYER NR. 12 -->
          <div class="tp-caption   tp-resizeme"
            data-frames='[{"delay":2970,"speed":1500,"frame":"0","from":"y:50px;opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
            data-height="none" data-hoffset="['325','260','0','0']" data-paddingbottom="[0,0,0,0]"
            data-paddingleft="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingtop="[0,0,0,0]"
            data-responsive_offset="on" data-textAlign="['left','left','center','center']" data-type="text"
            data-voffset="['200','186','176','156']" data-whitespace="nowrap" data-width="['650','650','600','500']"
            data-x="['center','center','center','center']" data-y="['middle','middle','middle','middle']"
            id="slide-43-layer-15" style="z-index: 16; white-space: nowrap;">
            <a class="btn btn-slider btn-rounded btn-blue btn-hvr-white" href="#portfolio">
              {{'landing.see_work' | translate}}
              <div class="btn-hvr-setting">
                <ul class="btn-hvr-setting-inner">
                  <li class="btn-hvr-effect"></li>
                  <li class="btn-hvr-effect"></li>
                  <li class="btn-hvr-effect"></li>
                  <li class="btn-hvr-effect"></li>
                </ul>
              </div>
            </a>
          </div>
          <!-- LAYER NR. 13 -->
          <div class="tp-caption   tp-resizeme"
            data-frames='[{"delay":990,"speed":1500,"frame":"0","from":"x:-50px;opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
            data-height="none" data-hoffset="['0','0','-412','-412']" data-paddingbottom="[0,0,0,0]"
            data-paddingleft="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingtop="[0,0,0,0]"
            data-responsive_offset="on" data-textAlign="['inherit','inherit','inherit','inherit']" data-type="image"
            data-visibility="['on','on','off','off']" data-voffset="['-1','-1','72','72']" data-whitespace="nowrap"
            data-width="none" data-x="['left','left','left','left']" data-y="['middle','middle','middle','middle']"
            id="slide-43-layer-16" style="z-index: 17;">
            <div class="rs-looped rs-slideloop" data-easing="" data-speed="5" data-xe="15" data-xs="-10" data-ye="0"
              data-ys="0"><img alt="" data-hh="['604px','510','510','510']" data-no-retina
                data-ww="['434px','350','350','350']" src="assets/images/vector-art-1.png"></div>
          </div>
          <!-- LAYER NR. 14 -->
          <div class="tp-caption tp-resizeme hide-cursor"
            data-actions='[{"event":"click","action":"scrollbelow","offset":"-50px","delay":"","speed":"1200","ease":"Power3.easeInOut"}]'
            data-fontsize="['20','20','20','17']"
            data-frames='[{"delay":10,"speed":300,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
            data-height="none" data-hoffset="['0','0','0','0']" data-paddingbottom="[0,0,0,0]"
            data-paddingleft="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingtop="[0,0,0,0]"
            data-responsive_offset="on" data-textAlign="['inherit','inherit','inherit','inherit']" data-type="text"
            data-voffset="['25','25','5','5']" data-whitespace="nowrap" data-width="none"
            data-x="['center','center','center','center']" data-y="['bottom','bottom','bottom','bottom']"
            id="slide-43-layer-17"
            style="z-index: 18; white-space: nowrap; cursor: pointer; font-size: 20px; line-height: 30px; font-weight: 400; color: #ffffff; letter-spacing: 0px;font-family:Montserrat;">
            Scroll <i class="ml-2 fas fa-long-arrow-alt-down"></i></div>
        </li>
      </ul>
      <div class="tp-bannertimer tp-bottom" style="visibility: hidden !important;"></div>
    </div>
  </div><!-- END REVOLUTION SLIDER -->
</section>
<!--Slider End-->
<!--About Us-->
<section class="pb-0" id="about-us">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 wow fadeInLeft">
        <div class="heading-area">
          <span class="sub-title">{{'landing.title' | translate}} </span>
          <h2 class="title">{{'landing.develop_your' | translate}} <span class="alt-color js-rotating">idea, proyecto, app</span> {{'landing.velocity' | translate}}</h2>
          <p class="para">{{'landing.industry_experience' | translate}}</p>
          <a class="btn btn-large btn-rounded btn-pink btn-hvr-blue mt-3" href="#portfolio">
            {{'landing.see_portfolio' | translate}}
            <div class="btn-hvr-setting">
              <ul class="btn-hvr-setting-inner">
                <li class="btn-hvr-effect"></li>
                <li class="btn-hvr-effect"></li>
                <li class="btn-hvr-effect"></li>
                <li class="btn-hvr-effect"></li>
              </ul>
            </div>
          </a>
        </div>
      </div>
      <div class="col-lg-6 wow fadeInRight">
        <div class="half-img mt-5 pt-4 mt-lg-0 pt-lg-0">
          <img alt="vector" src="assets/images/vector-art-2.png">
        </div>
      </div>
    </div>
  </div>
</section>
<!--About Us End-->
<!--Services Start-->
<section id="services">
  <div class="container">
    <div class="row">
      <div class="col-md-3">
        <div class="process-wrapp">
          <span class="pro-step blue"><i aria-hidden="true" class="fas fa-folder-open"></i></span>
          <h4 class="service-heading">{{'landing.startups_solutions' | translate}}</h4>
        </div>
      </div>
      <div class="col-md-3">
        <div class="process-wrapp">
          <span class="pro-step midnight"><i aria-hidden="true" class="fas fa-code"></i></span>
          <h4 class="service-heading">{{'landing.last_technologies' | translate}}</h4>
        </div>
      </div>
      <div class="col-md-3">
        <div class="process-wrapp">
          <span class="pro-step purple"><i aria-hidden="true" class="fas fa-cog"></i></span>
          <h4 class="service-heading">{{'landing.agile' | translate}}</h4>
        </div>
      </div>
      <div class="col-md-3">
        <div class="process-wrapp">
          <span class="pro-step pink"><i aria-hidden="true" class="fas fa-edit"></i></span>
          <h4 class="service-heading">{{'landing.5_exp' | translate}}</h4>
        </div>
      </div>
    </div>
  </div>
</section>
<!--Services End-->
<!--Counters Start-->
<!-- <section class="gradient-bg2" id="counters">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 pl-lg-4 order-lg-2 wow fadeInRight">
        <div class="heading-area">
          <span class="sub-title text-white">We are PinkBike company</span>
          <h2 class="title text-white">We have done some great <span class="js-rotating">stuff, idea</span>.
          </h2>
          <p class="para text-white">There are many variations of passages of Lorem Ipsum available, but the
            majority have suffered alteration in some form, by injected.</p>
        </div>
        <ul class="counter-list list-unstyled">
          <li class="counter-item">
            <i aria-hidden="true" class="fas fa-users text-white"></i>
            <h6 class="counter-number text-white"><span class="count">500</span>+</h6>
            <p class="sub-title text-white">Happy Clients</p>
          </li>
          <li class="counter-item">
            <i aria-hidden="true" class="fas fa-list-alt text-white"></i>
            <h6 class="counter-number text-white"><span class="count">1074</span>+</h6>
            <p class="sub-title text-white">Lines Of Code</p>
          </li>
          <li class="counter-item">
            <i aria-hidden="true" class="fas fa-award text-white"></i>
            <h6 class="counter-number text-white"><span class="count">600</span>+</h6>
            <p class="sub-title text-white">Project Completed</p>
          </li>
        </ul>
      </div>
      <div class="col-lg-6 wow fadeInLeft">
        <div class="half-img mt-5 pt-4 mt-lg-0 pt-lg-0">
          <img alt="vector" src="assets/images/vector-art-3.png">
        </div>
      </div>
    </div>
  </div>
</section> -->
<!--Counters End-->
<!--Team Start-->
<section class="text-center" id="team">
  <div class="container">
    <!--Row-->
    <div class="row">
      <div class="col-md-12">
        <div class="heading-area mx-570 mb-5">
          <span class="sub-title">{{'landing.we_are' | translate}}</span>
          <h2 class="title">{{'landing.we_have' | translate}} {{'landing.staff' | translate}}</h2>
          <!-- <p class="para">{{'landing.' | translate}}There are many variations of passages of Lorem Ipsum available, but the majority
            have suffered alteration in some form, by injected.</p> -->
        </div>
      </div>
    </div>
    <!--Row-->
    <div class="row wow fadeInUp">
      <div class="col-md-6">
        <div class="team-item">
          <!--Team Image-->
          <img alt="image" class="team-image" src="assets/images/team-img1.png">
          <!--Name-->
          <div class="name">
            <img alt="shape" src="assets/images/shape-10.png">
            <h6>Ignacio Rosa</h6>
          </div>
          <!--Designation-->
          <p class="designation mb-2">CO-FOUNDER</p>
          <!--Team Social-->
          <div class="team-social social-icon-bg-hvr">
            <a href="https://www.linkedin.com/in/ignacio-martin-rosa-b1a6852b/" target="_blank"><i aria-hidden="true"
                class="fab fa-linkedin-in"></i></a>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="team-item">
          <!--Team Image-->
          <img alt="image" class="team-image" src="assets/images/team-img3.png">
          <!--Name-->
          <!--Name-->
          <div class="name">
            <img alt="shape" src="assets/images/shape-11.png">
            <h6>Esteban Subissi</h6>
          </div>
          <!--Designation-->
          <p class="designation mb-2">CO-FOUNDER</p>
          <!--Team Social-->
          <div class="team-social social-icon-bg-hvr">
            <a href="https://www.linkedin.com/in/estebansubissi/" target="_blank"><i aria-hidden="true"
                class="fab fa-linkedin-in"></i></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--Team End-->
<!--Parallax Start-->
<!-- <section class="bg-light">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 wow fadeInLeft">
        <div class="heading-area">
          <span class="sub-title">We are PinkBike company</span>
          <h2 class="title"><span class="main-color js-rotating">Robust Design, Creative Idea</span> and
            Development Solutions</h2>
          <p class="para">There are many variations of passages of Lorem Ipsum available, but the majority
            have suffered alteration in some form, by injected humour, or randomised words which don't look
            even slightly believable. If you are going to use a passage of Lorem Ipsum.</p>
          <a class="btn btn-large btn-rounded btn-blue btn-hvr-pink mt-3" href="javascript:void(0);">Learn
            More
            <div class="btn-hvr-setting">
              <ul class="btn-hvr-setting-inner">
                <li class="btn-hvr-effect"></li>
                <li class="btn-hvr-effect"></li>
                <li class="btn-hvr-effect"></li>
                <li class="btn-hvr-effect"></li>
              </ul>
            </div>
          </a>
        </div>
      </div>
      <div class="col-lg-6 wow fadeInRight">
        <div class="half-img mt-5 pt-4 mt-lg-0 pt-lg-0">
          <img alt="image" src="assets/images/vector-art-4.png">
        </div>
      </div>
    </div>
  </div>
</section> -->
<!--Parallax End-->
<!--Portfolio Start-->
<section class="cube-portfolio1 text-center no-transition" id="portfolio">
  <div class="container">
    <!--Row-->
    <div class="row">
      <div class="col-md-12">
        <div class="heading-area mx-570 mb-lg-4 mb-3">
          <span class="sub-title">{{'landing.we_are' | translate}}</span>
          <h2 class="title">{{'landing.we_have' | translate}} <span class="alt-color js-rotating">diseñados, desarrollados</span> {{'landing.some_great' | translate}}</h2>
          <!-- <p class="para">{{'landing.' | translate}}There are many variations of passages of Lorem Ipsum available, but the majority
            have suffered alteration in some form, by injected.</p> -->
        </div>
      </div>
    </div>
    <!--Row-->
    <div class="row wow fadeIn">
      <div class="col-md-12">
        <div class="row">
          <ng-container *ngFor="let p of portfolio">
            <div class="col-md-4 col-12">

              <div class="cbp-item w-100">
                <div class="cbp-item_image">
                  <img alt="imagen de {{p.name}}" src="assets/images/portfolio/{{p.image}}">
                </div>
                <div class="p-3">
                  <h5 class="mb-1">{{p.name}}</h5>
                  <p class="mb-0">{{p.description}}</p>

                  <div class="links d-flex justify-content-center mt-3 mb-3">
                    <a class="icon d-flex flex-column justify-content-center mr-2 ml-2" [href]="p.availability.android"
                      *ngIf="p.availability.android" target="_blank">
                      <img class="mx-auto" width="24" src="assets/images/android.svg" alt="">
                      <span>android</span>
                    </a>
                    <a class="icon d-flex flex-column justify-content-center mr-2 ml-2" [href]="p.availability.ios"
                      *ngIf="p.availability.ios" target="_blank">
                      <img width="24" class="mx-auto" src="assets/images/ios.svg" alt="">
                    <span>ios</span></a>
                    <a class="icon d-flex flex-column justify-content-center mr-2 ml-2" [href]="p.availability.web"
                      *ngIf="p.availability.web" target="_blank">
                      <img width="24" class="mx-auto" src="assets/images/web.svg" alt="">
                    <span>web</span></a>
                  </div>
                  <button class="btn-submit" routerLink="proyect/{{p.name}}">{{'landing.see_proyect' | translate}}</button>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</section>
<!--Portfolio End-->
<!--Testimonial Start-->
<section class="gradient-bg1 text-center" id="clients">
  <div class="container">
    <!--Row-->
    <div class="row">
      <div class="col-md-12">
        <div class="heading-area mx-570 mb-5">
          <h2 class="title text-white m-0">{{'landing.some' | translate}} <span class="js-rotating">geniales, ideales</span> {{'landing.words' | translate}}</h2>
        </div>
      </div>
    </div>
    <!--Row-->
    <div class="row">
      <div class="col-md-12">
        <div class="owl-carousel wow zoomIn" id="testimonial-slider">
          <div class="item" *ngFor="let p of portfolio">
            <ng-container *ngFor="let pq of p.quotes">
            <p class="para">{{pq.text}}</p>
            <h5 class="name gradient-text1">{{pq.name}}</h5>
            <span class="designation">{{pq.position}}</span>
          </ng-container>
          </div>
        </div>
      </div>
    </div>
    <!--Testimonial Thumbs-->
    <div class="owl-dots" id="testimonials-avatar">
      <!--data-position[top,right,bottom,left]-->
      <button class="owl-dot active" data-position="22%,auto,auto,5%">
        <img alt="image" src="assets/images/avatar-1.png"></button>

      <button class="owl-dot active" data-position="30%,auto,auto,16%">
        <img alt="image" src="assets/images/avatar-2.png"></button>

      <button class="owl-dot active" data-position="auto,auto,38%,7%">
        <img alt="image" src="assets/images/avatar-3.png"></button>

      <button class="owl-dot active" data-position="auto,auto,23%,18%">
        <img alt="image" src="assets/images/avatar-7.png"></button>

      <!--data-position[top,right,bottom,left]-->
      <button class="owl-dot active" data-position="20%,19%,auto,auto">
        <img alt="image" src="assets/images/avatar-5.png"></button>

      <button class="owl-dot active" data-position="28%,6%,auto,auto">
        <img alt="image" src="assets/images/avatar-6.png"></button>

      <button class="owl-dot active" data-position="40%,15%,auto,auto">
        <img alt="image" src="assets/images/avatar-4.png"></button>

      <button class="owl-dot active" data-position="auto,21%,22%,auto">
        <img alt="image" src="assets/images/avatar-2.png"></button>
    </div>
  </div>
</section>
<!--Testimonial End-->
<!--Blog Start-->
<!-- <section class="bg-light" id="blog">
  <div class="container">
    <div class="row">
      <div class="col-md-12 text-center">
        <div class="heading-area mx-570 pb-lg-5 mb-5">
          <span class="sub-title">We are PinkBike company</span>
          <h2 class="title mb-0">Our <span class="alt-color js-rotating">latest blogs,recent news</span> will
            keep
            everyone updated</h2>
        </div>
      </div>
    </div>
    <div class="row wow fadeInUp">
      <div class="col-lg-4">
        <div class="news-item">
          <img alt="image" class="news-img" src="assets/images/blog-news-1.jpg">
          <div class="news-text-box">
            <span class="date main-color">October 29, 2020</span>
            <a href="PinkBike/agency/blog-list.html">
              <h4 class="news-title">Web design is fun</h4>
            </a>
            <p class="para">Lorem ipsum dolor sit amet consectetur adipiscing elit ipsum dolor sit am...</p>
            <a class="author d-flex align-items-center" href="javascript:void(0);">
              <img alt="image" class="author-img bg-blue" src="assets/images/avatar-1.png">
              <h5 class="author-name">Hena Sword</h5>
            </a>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="news-item">
          <img alt="image" class="news-img" src="assets/images/blog-news-2.jpg">
          <div class="news-text-box">
            <span class="date main-color">October 29, 2020</span>
            <a href="PinkBike/agency/blog-list.html">
              <h4 class="news-title">Future of websites</h4>
            </a>
            <p class="para">Lorem ipsum dolor sit amet consectetur adipiscing elit ipsum dolor sit am...</p>
            <a class="author d-flex align-items-center" href="javascript:void(0);">
              <img alt="image" class="author-img bg-purple" src="assets/images/avatar-2.png">
              <h5 class="author-name">David Villas</h5>
            </a>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="news-item">
          <img alt="image" class="news-img" src="assets/images/blog-news-3.jpg">
          <div class="news-text-box">
            <span class="date main-color">October 29, 2020</span>
            <a href="javascript:void(0);">
              <h4 class="news-title">Digital marketing</h4>
            </a>
            <p class="para">Lorem ipsum dolor sit amet consectetur adipiscing elit ipsum dolor sit am...</p>
            <a class="author d-flex align-items-center" href="javascript:void(0);">
              <img alt="image" class="author-img bg-pink" src="assets/images/avatar-5.png">
              <h5 class="author-name">Jhon Walker</h5>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> -->
<!--Blog End-->
<!--Client Map-->

<section class="cube-portfolio1 text-center no-transition" id="client">

   
  <div class="container">
      <div class="row">
          <div class="col-md-12">
      <div class="heading-area mx-570 mb-lg-4 mb-3">
      <h2 class="title">{{'landing.technologies_' | translate}} </h2>
      </div> 
    </div> </div> 

    <div class="row">
      <div class="col-md-12">
        <!--Client Slider-->
        <div class="owl-carousel partners-slider">
          <!--Item-->
          <div class="logo-item"><img alt="client-logo" src="assets/images/technologies/android.png"></div>
          <!--Item-->
          <div class="logo-item"><img alt="client-logo" src="assets/images/technologies/angular.png"></div>
          <!--Item-->
          <div class="logo-item"><img alt="client-logo" src="assets/images/technologies/aws.png"></div>
          <!--Item-->
          <div class="logo-item"><img alt="client-logo" src="assets/images/technologies/gcp.png"></div>
        
          <div class="logo-item"><img alt="client-logo" src="assets/images/technologies/java.png"></div>
          <div class="logo-item"><img alt="client-logo" src="assets/images/technologies/mercadopago.png"></div>
          <div class="logo-item"><img alt="client-logo" src="assets/images/technologies/nodejs.png"></div>

          <div class="logo-item"><img alt="client-logo" src="assets/images/technologies/mongodb.png"></div>
          <div class="logo-item"><img alt="client-logo" src="assets/images/technologies/mlkit.png"></div>
          <div class="logo-item"><img alt="client-logo" src="assets/images/technologies/perl.png"></div>
          <div class="logo-item"><img alt="client-logo" src="assets/images/technologies/mysql.png"></div>
          <div class="logo-item"><img alt="client-logo" src="assets/images/technologies/swift.png"></div>
        
        
        </div>
      </div>
    </div>
  </div>
</section>
<!--Client End-->
<!--Map Start-->
<!-- <div class="p-0 gradient-bg2 map-area">
  <div class="container">
    <div class="full-map" id="map"></div>
  </div>
</div> -->
<!--Map End-->
<!--Contact Start-->
<section class="contact-us" id="contact">
  <div class="container">
    <div class="row align-items-top">
      <div class="col-lg-5 order-lg-2 wow fadeInRight">
        <div class="contact-detail">
          <div class="contact-dots" data-dots=""></div>
          <div class="heading-area pb-5">
            <h2 class="title mt-0 pb-1">{{'landing.location' | translate}}</h2>
            <p>
              <a href="https://areatresworkplace.com">Area 3 Coworking</a>
              <br/>El Salvador 5218, CABA, Argentina 
            </p>
          </div>
        </div>
      </div>
    <div class="col-lg-7 mt-4 pt-3 mt-lg-0 pt-lg-0 wow fadeInLeft">
        <div class="heading-area pb-2">
          <h2 class="title mt-0">{{'landing.get_in_touch' | translate}}</h2>
        </div>
        <form class="d-flex flex-column" [formGroup]="message" #f="ngForm" (ngSubmit)="sendMessage()">
          <input placeholder="Email" matInput formControlName="email" required>
          <textarea placeholder="Mensaje" matInput formControlName="message" #input maxlength="144" required></textarea>
    
          <button class="btn-submit" type="submit">{{'common.send' | translate}}</button>
        </form>
      </div> 
    </div>
  </div>
</section>
<!--Contact End-->
<!--Footer Start-->
<footer class="footer-style-1 bg-light">
  <div class="container">
    <div class="row align-items-center">
      <!--Social-->
      <div class="col-md-6">
       <!--  <div class="footer-social">
          <ul class="list-unstyled">
            <li><a class="wow fadeInUp" href="javascript:void(0);"><i aria-hidden="true"
                  class="fab fa-facebook-f"></i></a></li>
            <li><a class="wow fadeInDown" href="javascript:void(0);"><i aria-hidden="true"
                  class="fab fa-twitter"></i></a></li>
            <li><a class="wow fadeInUp" href="javascript:void(0);"><i aria-hidden="true"
                  class="fab fa-google-plus-g"></i></a></li>
            <li><a class="wow fadeInDown" href="javascript:void(0);"><i aria-hidden="true"
                  class="fab fa-linkedin-in"></i></a></li>
            <li><a class="wow fadeInUp" href="javascript:void(0);"><i aria-hidden="true"
                  class="fab fa-instagram"></i></a></li>
            <li><a class="wow fadeInDown" href="javascript:void(0);"><i aria-hidden="true"
                  class="fab fa-pinterest-p"></i></a></li>
          </ul>
        </div> -->
      </div>
      <!--Text-->
      <div class="col-md-6 text-md-right">
        <p class="company-about fadeIn">© 2021 PinkBike
        </p>
      </div>
    </div>
  </div>
</footer>
<!--Footer End-->
<!--Animated Cursor-->
<div id="aimated-cursor">
  <div id="cursor">
    <div id="cursor-loader"></div>
  </div>
</div>
<!--Animated Cursor End-->
<!--Scroll Top Start-->
<span class="scroll-top-arrow"><i class="fas fa-angle-up"></i></span>
