import { nullSafeIsEquivalent } from '@angular/compiler/src/output/output_ast';

export class Constants {
    public static readonly portfolio: any = [
    {
      name: 'Goonder', 
      image: 'goonder.png',
      date : "2019-01-01",
      marketingDescription: "El tinder de las inversiones",
      shortDescription: "Aplicación movil que ofrece oportunidades de inversión en mercados de capitales",
      description: 'Goonder es una aplicación que ofrece diferentes alternativas de inversión en mercado de capitales y criptomonedas. Utilizando una experiencia de usuario amigable al estilo tinder, los usuario pueden elegir día tras día en que activos invertir. Las oportunidades son criadas a través de un algoritmo de optimización que realiza un analisis técnico y fundamental de más de 3000 activos luego del cierre de los mercados más liquidos del mundo (EEUU, Europa y Asia)',
      filter:['fintech'],
      technologies: ["swift", "android", "nodejs", "angular", "mysql", "aws", "perl", "java"],
      mockups: ["resources/goonder/1","resources/goonder/2","resources/goonder/3","resources/goonder/4","resources/goonder/5"],
      quotes:[],
      availability : {
        ios: "https://apps.apple.com/ar/app/goonder/id1274664590",
        android: "https://play.google.com/store/apps/details?id=goonder.goonder&hl=es_AR&gl=US",
        web: "https://www.goonder.com/EN/index.html"
      }
    },
    {
      name: 'TuId Vida', 
      image: 'tuid.png',
      date : "2019-12-01",
      marketingDescription: "Autenticación biometrica y fe de vida de jubilados",
      shortDescription: "A través de TuId Vida los jubilados pueden dar la fe de vida sin la necesidad de acercarse a la entidad bancaria.",
      description: 'Aplicación nativa para IOS y Android, que permite a los jubilados registrarse utilizando su rostro y fotos del documento nacional de identidad, y un gesto como puede ser un pestaneo. Esta herramienta evita que personas de edad avanzada tengan que acercarse a las entidades financieras a dar la fe de vida para cobrar sus jubilaciones y pensiones. La app es una marcablanca y facilmente adaptable a cualquier entidad pagadora',
      filter:['fintech', 'security','authentication','face-recognition', 'ai'],
      technologies: ["swift", "android", "nodejs", "angular", "mysql", "aws", "mlkit"],
      mockups: ["resources/tuid/1","resources/tuid/2","resources/tuid/3","resources/tuid/4"],
      quotes:[
          
      ],
      availability : {
        ios: "https://apps.apple.com/ar/app/tuid-vida/id1488929611",
        android: "https://play.google.com/store/apps/details?id=com.autenticar.tuid&hl=es_419&gl=US",
        web: "https://www.tuid.ar/productos/tuid-vida"
      }
    },
    {
      name: 'Cuponeala', 
      image: 'cuponeala.jpg',
      date : "2020-05-01",
      marketingDescription: "MarketPlace de cupones",
      shortDescription: "Cuponeala es una app que ofrece descuentos en más de 10 categorias de comercios tradicionales.",
      description: 'Solución omnicanal que le ofrece a los comercios tradicionales herramientas para generar cupones de descuentos y ofrecerlos al publico consumidor a través de aplicaciones nativas para Android y IOS. Usuarios pueden acceder a increibles descuento unicamente a través de su documento nacional de identidad',
      filter:['marketing', 'ecommerce', 'discounts'],
      technologies: ["swift", "android", "nodejs", "angular", "mysql", "aws", "gcp"],
      mockups: ["resources/cuponeala/1","resources/cuponeala/2","resources/cuponeala/3","resources/cuponeala/4","resources/cuponeala/5"],
      quotes:[
           
      ],
      availability : {
        ios: "https://apps.apple.com/ar/app/cuponeala/id1497733224",
        android: "https://play.google.com/store/apps/details?id=com.cuponeala&hl=en_US",
        web: "https://www.cuponeala.com"
      }
    },
    {
      name: 'Ánima', 
      image: 'anima.png',
      date : "2020-06-01",
      marketingDescription: "Gestiona a tus pacientes de una manera ágil y simple.",
      shortDescription: "Web para secretarias que permite tener un control de las comunicaciones entre el médico y el paciente.",
      description: 'Sistema de gestión de consultorio y comunicaciones entre médico y pacientes. Permite generar campañas segmentadas de mails y SMS ofreciendo estadisticas de resultados (aperturas, envios, rechazos) a los médicos',
      filter:['health', 'marketing'],
      technologies: ["nodejs", "angular", "mysql", "aws", "gcp"],
      quotes:[
           
      ],
      availability : {
        ios: null,
        android: null,
        web: null
      }
    },
    {
      name: 'Fidelity', 
      image: 'fidelity.png',
      date : "2020-10-01",
      marketingDescription: "Que tus clientes sumen puntos y ganen descuentos y productos",
      shortDescription: "Sistema de fidelización por puntos.",
      description: 'Fidelity es un sistema marcablanca que permite a cualquier comercio disponer de un sitema de fidelización por puntos integrado a su sistema de facturación. A través de las apps, los clientes puede ver el catalogo y canjear sus puntos por productos y/o descuentos',
      filter:['ecommerce', 'marketing', 'discounts'],
      technologies: ["nodejs", "angular", "android", "swift", "aws","mongodb"],
      mockups: ["resources/fidelity/1","resources/fidelity/2","resources/fidelity/3","resources/fidelity/4","resources/fidelity/5"],
      quotes:[
           
      ],
      availability : {
        ios: null,
        android: null,
        web: null
      }
    },
    
    {
      name: 'Inspay', 
      image: 'inspay.png',
      date : "2019-08-01",
      marketingDescription: "Sistema de hombanking para mutuales",
      shortDescription: "Aplicación nativa que permite a los usuarios de las mutuales hacer todas sus transacciones desde su celular.",
      description: 'INSPAY es una app nativa que funciona como una billetera virtual/homebanking de mutuales. Los usuarios tienen la capacidad de solicitar préstamos, hacer pagos de facturas y servicios, transferir dinera a otras cuentas, ver los movimientos de fondos, solicitar acceso a cajeros de la red de INSRED, etc. ',
      filter:['fintech', 'payments', 'e-wallet'],
      technologies: ["android", "swift"],
      quotes:[
          
      ],
      availability : {
        ios: "https://apps.apple.com/ar/app/ins-pay/id1520476742",
        android: null,
        web: null,
      }
    },
    {
      name: 'Appark', 
      image: 'appark.jpeg',
      logo: 'appark.png',
      date : "2021-10-01",
      marketingDescription: "Reservá y pagá tu lugar de estacionamiento desde la app",
      shortDescription: "Appark es una app que le permite a los usuarios reservar el estacionamiento urbano directamente desde la app sin tener que acercarse a las ticketeadoras.",
      description: 'Appark es una app que le permite a los usuarios reservar el estacionamiento urbano directamente desde la app sin tener que acercarse a las ticketeadoras. Permite gestionar el tiempo de la reserva desde cualquier lugar',
      filter:['smart-cities', 'parking'],
      technologies: [ "swift","android"],
      mockups: [],
      quotes:[],
      availability : {
        ios: null,
        android: null,
        web: null
      }
    },

    {
      name: 'Bitex', 
      image: 'bitex.png',
      logo: 'bitex.png',
      date : "2021-10-01",
      marketingDescription: "Las mejores oportunidades de inversión de Hong Kong, USA y Europa",
      shortDescription: "Bitex es una app que comparte diariamente estrategias de inversión para mercados de acciones.",
      description: 'A través de un algoritmo de inteligencia artificial que estudia diariamente los mercados, la aplicación brinda a sus usuarios una serie de estrategias de inversión basado en su perfil de riesgo. La aplicación permite enlazar tu cuenta con la de diferentes brokers, con lo cual una vez que se realiza la selección de las estrategias en las que se desea invertir estas son compartidas con el broker para que se haga cargo de la ejecución de las órdenes.',
      filter:['fintech', 'investments'],
      technologies: ["nodejs", "angular", "swift","android", "aws"],
      mockups: [],
      quotes:[],
      availability : {
        ios: null,
        android: null,
        web: "https://admin.bitx.mobi"
      }
    },
    {
      name: 'Fudsy', 
      image: 'fudsy.png',
      logo: 'fudsyV.png',
      date : "2020-11-01",
      marketingDescription: "Pedi tu comida desde la mesa o desde tu casa, paga y listo.",
      shortDescription: "Una aplicación que permite a los locales gastronomicos cargar su menu y a los comensales a través del scan de un código QR pedir y pagar desde cualquier lugar.",
      description: 'Fudsy es un sistema web para comercios gastrónomicos, que busca acercar a los restaurantes con sus clientes. Cuenta con diferentes modelos de órdenes (DineIn, Delivery, TakeAway, Drive Through) en las cuales los comensales pueden acceder escaneando su código QR pedir y pagar desde el mismo sistema.',
      filter:['ecommerce', 'restaurants', 'food'],
      technologies: ["nodejs", "angular", "mongodb","mercadopago","gcp", "aws"],
      mockups: ["resources/fudsy/4","resources/fudsy/5","resources/fudsy/6","resources/fudsy/7"],
      quotes:[],
      availability : {
        ios: null,
        android: null,
        web: "https://fudsy.app"
      }
    },
    {
      name: 'ACV Vida', 
      image: 'acvvida169.png',
      logo: 'acvvida.png',
      date : "2021-06-01",
      marketingDescription: "Todo lo que tenes que saber sobre el ACV y como tratarlo",
      shortDescription: "La APP ACV Vida se ha creado para ayudar a las personas que presenten síntomas de un ataque cerebrovascular (ACV) a distinguir dichos síntomas y poder llegar a tiempo al tratamiento evitando la instalación de secuelas o salvar su vida.",
      description: `La APP «ACV Vida» se ha creado para ayudar a las personas que presenten síntomas de un ataque cerebrovascular (ACV) a distinguir dichos síntomas y poder llegar a tiempo al tratamiento evitando la instalación de secuelas o salvar su vida.
      Para lograrlo la APP ofrece una serie de acciones que se ponen en marcha cuando la persona, al reconocer los síntomas que predicen el ACV, pulsa el Botón de Emergencia. 
      Inmediatamente el usuario puede visualizar en un mapa los centros de salud más cercanos para acudir inmediatamente. Al mismo tiempo, la aplicación envía emails y mensajes de texto (SMS) a los contactos previamente configurados, informándoles la ubicación en la que se encuentra. También el sistema hará un llamado telefónico al número que se haya seleccionado para recibir ayuda en forma rápida.
      Muchas veces, las personas no saben distinguir los síntomas o no les dan importancia, mientras que otros desconocen los factores de riesgo y protección. Por eso, la App ofrece también información sobre temas relacionados con la prevención, tratamiento y rehabilitación del ACV. Además, su valor está en la tranquilidad que ofrece, a quien la descargue, saber que utilizar el Botón de Emergencia permitirá que sea localizado y auxiliado rápidamente.
      La Fundación ACVvida es una organización sin fines de lucro que está formada por profesionales de la salud y miembros voluntarios, liderados por el Dr. Rolando Cárdenas Sánchez, unidos por un mismo objetivo: promover la prevención del ataque cerebrovascular.
      Por ello, se consideró importante desarrollar esta APP para ofrecerla de forma gratuita a la comunidad.
      Consideramos que una prevención exitosa debe trabajar tres estamentos:
      1°) La población en general para que tome conciencia de que esta enfermedad es una emergencia. Aprender a reconocer los síntomas del ACV, saber cómo actuar y a dónde recurrir en caso de necesidad son los objetivos centrales de esta aplicación.
      2°) El cuerpo médico para actualizarlo en los adelantos científicos y conceptuales de manejo integral del ataque cerebral. Es esencial tomar decisiones adecuadas y utilizar protocolos de tratamiento actuales y dentro del tiempo requerido.
      3°) El Estado y las entidades privadas de salud para que sea obligatoria la atención primaria en cualquier hospital o sanatorio cercano donde llegue el paciente con un ACV.
      Es necesario reducir al máximo los tiempos para su diagnóstico y tratamiento.
      Nuestra iniciativa tiene como eje fundamental el trabajar en conjunto para disminuir la mortalidad y las secuelas discapacitantes sabiendo que el ACV es una emergencia, que puede prevenirse y que tiene tratamiento.
      En un ataque cerebral o ACV la sangre deja de llegar al cerebro. Las arterias y las venas pueden obstruirse o romperse y las células del cerebro comienzan a morir porque dejan de recibir el oxígeno que necesitan para funcionar.
      El ataque cerebral daña al cerebro y puede afectar todo el cuerpo, incluso causar incapacidad permanente. Una persona puede sufrir parálisis, tener problemas para pensar, hablar, moverse y hasta padecer problemas emocionales como resultado de haber sufrido un ACV.
      Accidente cerebro vascular, ACV, ictus, embolia, derrame cerebral, apoplejía, stroke, trombosis, todos los términos se refieren a un ataque cerebro vascular.
      Lo más importante: debemos prevenirlo y saber cómo actuar a tiempo.`,
      filter:['utilities', 'health'],
      technologies: ["nodejs", "angular", "mongodb","mercadopago","gcp", "aws", "android", "swift"],
      mockups: [],
      quotes:[ 
      ],
      availability : {
        ios: "https://apps.apple.com/sa/app/acv-vida/id1572205028",
        android: "https://play.google.com/store/apps/details?id=ar.com.guiacv",
        web: "https://acvvida.org"
      }
    },
    {
      name: 'Diktio', 
      image: 'diktio.png',
      logo: 'diktio.png',
      date : "2021-10-20",
      marketingDescription: "Es una multiplataforma donde los usuarios acceden de un modo sencillo y con la experiencia de la atención medica privada a todos los servicios referidos a la salud de las personas.",
      shortDescription: "Es una multiplataforma donde los usuarios acceden de un modo sencillo y con la experiencia de la atención medica privada a todos los servicios referidos a la salud de las personas.",
      description: `Diktio Salud es una Plataforma donde los usuarios encuentran de un modo sencillo y accesible todo tipo de servicios para la salud humana. Descubrí en nuestra App una forma de lograr una atención rápida, accesible, confiable y sin costos fijos mensuales, abonando con importantes descuentos y sólo por el Servicio Médico que utilices y con los siguientes beneficios:
      - Sin cuotas mensuales
      - Amplia red de prestadores con Profesionales Certificados
      - Conociendo previamente el currículum y la calificación de cada profesional
      - Eligiendo su formato de consulta: presencial o videoconsulta (telemedicina)
      - Encontrando turnos a corto plazo
      - Abonando el servicio desde la Billetera Virtual incluida sin cargo
      - Ahorrando con los Valores preferenciales en los servicios
      - Sintiendo calidad de la atención privada
      - Compartiendo la experiencia con los otros usuarios
      - Conservando siempre a mano tu historial de atención
      - Cuidando tu salud con Transparencia, Privacidad y Seguridad`,
      filter:['utilities', 'health'],
      technologies: ["nodejs", "angular", "mongodb","mercadopago","android", "swift","gcp"],
      mockups: ["resources/diktio/1","resources/diktio/2","resources/diktio/3","resources/diktio/4"],
      quotes:[ 
      ],
      availability : {
        ios: "https://apps.apple.com/ar/app/diktio/id1567132171",
        android: "https://play.google.com/store/apps/details?id=com.diktioapp",
        web: "https://www.diktiosalud.com/"
      }
    },
    {
      name: 'Movypay', 
      image: 'movypay.png',
      logo: 'movypay.jpg',
      date : "2021-10-20",
      marketingDescription: "La app que te permite pagar el transporte público scaneando el codigo QR.",
      shortDescription: "La app que te permite pagar el transporte público scaneando el codigo QR.",
      description: `La app que te permite pagar el transporte público scaneando el codigo QR. Con esta app podes pagar tus viajes de transporte público en las líneas asociadas al sistema de la ciudad de Iquique, Chile.`,
      filter:['utilities', 'smart-cities'],
      technologies: ["android", "swift"],
      mockups: ["resources/movypay/1","resources/movypay/2","resources/movypay/3","resources/movypay/4"],
      quotes:[ 
      ],
      availability : {
        ios: "https://apps.apple.com/ar/app/movypay/id1589857363",
        android: "https://play.google.com/store/apps/details?id=cl.movypay",
        web: null
      }
    }
  ]}

  //falta briket, agrosalta, bicat, amfac, autenticador