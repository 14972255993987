import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { LandingComponent } from './public/landing/landing.component'
import { ProyectDetailsComponent } from './public/landing/proyect-details/proyect-details.component'



const routes: Routes = [
  {path: '', component: LandingComponent},
  {path: 'proyect/:proyect', component: ProyectDetailsComponent},
  { path: '**', redirectTo: '', pathMatch: 'full' }
] 

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
