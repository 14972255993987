<header class="cursor-light">
  <!--Navigation-->
  <nav class="navbar navbar-top-default navbar-expand-lg navbar-gradient nav-icon alt-font navbar-top-default">
    <div class="container">
      <a class="logo link scroll" routerLink="/" title="Logo">
        <!--Logo Default-->
        <img alt="logo" class="logo-dark default" src="assets/images/logo-white.png">
      </a>
      <!--Nav Links-->
      <div class="collapse navbar-collapse" id="agency">
        <div class="navbar-nav ml-auto">
          <a class="nav-link link scroll active" (click)="getUrl() == 'proyect' ? goTo('#home') : ''"
            href="#home">{{'landing.home' | translate}}</a>
          <a class="nav-link link scroll" (click)="getUrl() == 'proyect' ? goTo('#about-us') : ''"
            href="#about-us">{{'landing.about_us' | translate}}</a>
          <a class="nav-link link scroll" (click)="getUrl() == 'proyect' ? goTo('#portfolio') : ''"
            href="#portfolio">{{'landing.our_work' | translate}}</a>
          <a class="nav-link link scroll" (click)="getUrl() == 'proyect' ? goTo('#clients') : ''"
            href="#clients">{{'landing.clients' | translate}}</a>
          <!-- <a class="nav-link link scroll" href="#blog">Our Blog</a> -->
          <!-- <a class="nav-link link scroll" href="#contact">{{'landing.contact' | translate}}</a> -->
          <span class="menu-line"><i aria-hidden="true" class="fa fa-angle-down"></i></span>
        </div>
        <a class="btn btn-medium btn-rounded btn-transparent-white btn-hvr-white ml-3" data-animation-duration="500"
          data-fancybox data-src="#animatedModal" href="javascript:void(0);">{{'landing.get_quote' | translate}}
          <div class="btn-hvr-setting">
            <ul class="btn-hvr-setting-inner">
              <li class="btn-hvr-effect"></li>
              <li class="btn-hvr-effect"></li>
              <li class="btn-hvr-effect"></li>
              <li class="btn-hvr-effect"></li>
            </ul>
          </div>
        </a>
      </div>
      <!--Menu Button
      <button class="fullnav-toggler link" id="full-menu-1" type="button">
        <span class="line"></span>
        <span class="line"></span>
        <span class="line"></span>
      </button>-->
    </div>
  </nav>

  <!--Full menu-->
  <div class="nav-holder main style-2 alt-font">
      <!--Menu Button-->
      <button class="fullnav-close link" type="button">
        <span class="line"></span>
        <span class="line"></span>
        <span class="line"></span>
      </button>
      
      <div class="container">
        <div class="shape-left">
          <nav class="navbar full-menu-navigation left">
            <ul class="list-unstyled">
              <li><a class="link scroll" (click)="getUrl() == 'proyect' ? goTo('#home') : ''" href="#home">
                  <span class="anchor-circle"></span>
                  <span class="anchor-text">{{'landing.home' | translate}}</span>
                </a></li>
              <li><a class="link scroll" (click)="getUrl() == 'proyect' ? goTo('#about-us') : ''" href="#about-us">
                  <span class="anchor-circle"></span>
                  <span class="anchor-text">{{'landing.about_us' | translate}}</span>
                </a></li>
              <li><a class="link scroll" (click)="getUrl() == 'proyect' ? goTo('#portfolio') : ''" href="#portfolio">
                  <span class="anchor-circle"></span>
                  <span class="anchor-text">{{'landing.our_work' | translate}}</span>
                </a></li>
              <li><a class="link scroll" (click)="getUrl() == 'proyect' ? goTo('#clients') : ''" href="#clients">
                  <span class="anchor-circle"></span>
                  <span class="anchor-text">{{'landing.clients' | translate}}</span>
                </a></li>
                <li class="d-md-none"><a class="link scroll" (click)="goTo('#contact')">
                    <span class="anchor-circle"></span>
                    <span class="anchor-text">{{'landing.contact' | translate}}</span>
                  </a></li>
              <!-- <li><a class="link scroll" href="#blog">
                              <span class="anchor-circle"></span>
                              <span class="anchor-text">Novedades</span>
                          </a></li> -->
              <!--    <li><a class="link scroll" href="#contact">
                              <span class="anchor-circle"></span>
                              <span class="anchor-text">{{'landing.contact' | translate}}</span>
                          </a></li> -->
            </ul>
            <span class="full-menu-dot" style="transform: scale(0);"></span>
          </nav>
          
        </div>
  
      </div>
    </div>
  
  <!--Get Quote Model Popup-->
  <div class="animated-modal hidden quote-content" id="animatedModal">
    <!--Heading-->
    <div class="heading-area pb-2 mx-570">
      <span class="sub-title">Somos PinkBike Product Development</span>
      <h2 class="title mt-2">Contanos sobre tu <span class="alt-color js-rotating">idea, proyecto, web, app</span></h2>
    </div>
    <form class="d-flex flex-column" [formGroup]="message" #f="ngForm" (ngSubmit)="sendMessage()">
      <input placeholder="Email" matInput formControlName="email" required>
      <textarea placeholder="Mensaje" matInput formControlName="message" #input maxlength="144" required></textarea>

      <button class="btn-submit" type="submit">{{'common.send' | translate}}</button>
    </form>
    <!--Contact Form-->
    <!-- <form class="contact-form" id="modal-contact-form-data">
            <div class="row">
                <div class="col-12" id="quote_result"></div>
                <div class="col-md-6">
                    <div class="form-group">
                        <input class="form-control" id="quote_name" name="quoteName" placeholder="Nombre" required=""
                               type="text">
                    </div>
                    <div class="form-group">
                        <input class="form-control" id="quote_contact" name="userPhone" placeholder="Celular " required=""
                               type="text">
                    </div>
                    <div class="form-group">
                        <input class="form-control" id="quote_type" name="projectType" placeholder="Tipo de proyecto" required=""
                               type="text">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <input class="form-control" id="quote_email" name="userEmail" placeholder="Email" required=""
                               type="email">
                    </div>

                    <div class="form-group">
                        <input class="form-control" id="quote_budget" name="quoteBudget" placeholder="Presupuesto" required=""
                               type="text">
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group">
                        <textarea class="form-control" id="quote_message"
                                  name="userMessage" placeholder="Explicanos tu proyecto con detalle. Lo ideal es que nos cuentes la funcionalidades que tenes en mente."></textarea>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-check">
                        <label class="checkbox-lable">Contacto por teléfono
                            <input type="checkbox">
                            <span class="checkmark"></span>
                        </label>
                    </div>
                    <a class="btn btn-large btn-rounded btn-blue btn-hvr-pink modal_contact_btn" href="javascript:void(0);"
                       id="quote_submit_btn"><i class="fa fa-spinner fa-spin mr-2 d-none" aria-hidden="true"></i><b>Send Message</b>
                        <div class="btn-hvr-setting">
                            <ul class="btn-hvr-setting-inner">
                                <li class="btn-hvr-effect"></li>
                                <li class="btn-hvr-effect"></li>
                                <li class="btn-hvr-effect"></li>
                                <li class="btn-hvr-effect"></li>
                            </ul>
                        </div>
                    </a>
                </div>
            </div>
        </form> -->
  </div>
</header>
